<template>
  <div class="flex flex-col space-y-6 py-3 ml-5">
    <div
      class="flex items-center text-teal mt-3 cursor-pointer w-24"
      @click="reRoute"
    >
      <span class="material-icons-outlined"> arrow_back </span>
      go Back
    </div>
    <!-- NEW RECRUITMENT APPLICATIONS -->
    <div v-if="!newIsLoading" class="bg-lightGrey rounded-xl w-11/12 shadow-md">
      <!-- <template v-if="!isLoading"> -->
      <template v-if="getNewApplicationsHiringRequest?.data.length > 0">
        <RecuitmentTable
          tableTitle="New Recruitment Applications"
          :tableHeadings="tableHeaders"
          :data="getNewApplicationsHiringRequest?.data"
          :link="'hq-staff-request-form'"
          :myParameter="'staffRequestForm'"
          :totalPages="getNewApplicationsHiringRequest.last_page"
          @page-change="fetchPageData('pending', $event)"
        >
        </RecuitmentTable>
      </template>
      <no-data-found v-else />
    </div>
    <template v-else>
      <table-skeleton />
    </template>
    <!-- APPROVED RECRUITMENT APPLICATIONS -->
    <div
      v-if="!approvedIsLoading"
      class="bg-lightGrey rounded-xl w-11/12 shadow-md"
    >
      <template v-if="getApprovedHiringRequest.data.length > 0">
        <RecuitmentTable
          tableTitle="Approved Recruitment Applications"
          :tableHeadings="tableHeaders"
          :data="getApprovedHiringRequest.data"
          :link="'hq-staff-request-form'"
          :myParameter="'staffRequestForm'"
          :totalPages="getApprovedHiringRequest.last_page"
          @page-change="fetchPageData('approved', $event)"
        >
        </RecuitmentTable>

        <!-- <table class="text-center w-full text-grey my-2"> -->
        <!-- <table-header :header="tableHeaders" /> -->
        <!-- <table-row
          v-for="(row, $index) in tableRows"
          :key="$index"
          :row="row"
        />
      </table> -->
      </template>
      <no-data-found v-else />
    </div>
    <template v-else>
      <table-skeleton />
    </template>
    <!-- ESCALATED RECRUITMENT APPLICATIONS -->
    <div
      v-if="!escalatedIsLoading"
      class="bg-lightGrey rounded-xl w-11/12 shadow-md"
    >
      <template v-if="getEscalatedHiringRequest.data.length > 0">
        <RecuitmentTable
          tableTitle="Escalated Recruitment Applications"
          :tableHeadings="tableHeaders"
          :data="getEscalatedHiringRequest.data"
          :link="'hq-staff-request-form'"
          :myParameter="'staffRequestForm'"
          :totalPages="getEscalatedHiringRequest.last_page"
          @page-change="fetchPageData('escalated', $event)"
        >
        </RecuitmentTable>
      </template>
      <no-data-found v-else />
    </div>
    <template v-else>
      <table-skeleton />
    </template>
    <!-- DECLINED RECRUITMENT APPLICATIONS -->
    <div
      v-if="!declinedIsLoading"
      class="bg-lightGrey rounded-xl w-11/12 shadow-md"
    >
      <template v-if="getDeclinedHiringRequest.data.length > 0">
        <RecuitmentTable
          tableTitle="Declined Recruitment Applications"
          :tableHeadings="tableHeaders"
          :data="getDeclinedHiringRequest.data"
          :link="'hq-staff-request-form'"
          :myParameter="'staffRequestForm'"
          :totalPages="getDeclinedHiringRequest.last_page"
          @page-change="fetchPageData('declined', $event)"
        >
        </RecuitmentTable>

        <my-pagination
          :options="options"
          v-model="declinedPage"
          :records="getDeclinedHiringRequest.total"
          :per-page="getDeclinedHiringRequest.per_page"
          @paginate="myCallback($event, 'declined')"
        >
        </my-pagination>
      </template>
      <no-data-found v-else />
    </div>
    <template v-else>
      <table-skeleton />
    </template>
  </div>
</template>

<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
import tableSkeleton from "@/components/ui/baseComponents/skeletons/tableSkeleton.vue";
// import tableHeader from "@/components/ui/baseComponents/tableHeader.vue";
// import tableRow from "@/components/ui/baseComponents/tableRow.vue";

import RecuitmentTable from "@/components/newUi/tables/RecuitmentTable.vue";

// import baseDataTable from "@/components/ui/baseComponents/paginate.vue";
// import faker from "@faker-js/faker";
export default {
  provide() {
    return { headers: this.tableHeaders };
  },
  components: {
    noDataFound,
    // baseDataTable,
    // tableHeader,
    // tableRow,
    tableSkeleton,

    RecuitmentTable,
  },
  async created() {
    console.log(" Current Page number is ========>", this.currentPage);
    this.newIsLoading = true;
    this.approvedIsLoading = true;
    this.declinedIsLoading = true;
    this.escalatedIsLoading = true;
    await this.fetchAllHiringRequests("pending", this.page);
    this.newIsLoading = false;
    await this.fetchAllHiringRequests("approved", this.currentPage);
    this.approvedIsLoading = false;
    await this.fetchAllHiringRequests("escalated", this.page);
    this.escalatedIsLoading = false;
    await this.fetchAllHiringRequests("declined", this.page);
    this.declinedIsLoading = false;
  },
  mounted() {
    // console.log(this.getApprovedHiringRequest?.last_page);
    // console.log(this.approvedTotalPages);
    if (this.$route.query.success) {
      //   const hireRequestData = {
      //     practice: null,
      //     job_title: null,
      //     contract_type: null,
      //     department: null,
      //     reporting_to: null,
      //     start_date: null,
      //     starting_salary: null,
      //     reason_for_recruitment: null,
      //     comment: null,
      //     job_specification: null,
      //     person_specification: null,
      //     rota_information: null,
      //     name: null,
      //     start_time: null,
      //     end_time: null,
      //     break_time: null,
      //     repeat_days: null,
      //   };
      // this.$store.commit("MAnewHire/setNewHiringRequest", hireRequestData);
      // this.$store.commit("profile/setWorkTimings", null);
    } else if (this.$route.query.error) {
      this.$toast.error(`Something went wrong`);
    }
  },
  data() {
    return {
      tableHeaders: ["Practice", "Department", "Position", "Contract Type"],
      newIsLoading: false,
      approvedIsLoading: false,
      escalatedIsLoading: false,
      declinedIsLoading: false,
      image: null,
      nullPage: 1,
      approvedPage: 1,
      escalatedPage: 1,
      declinedPage: 1,
      options: {
        template: markRaw(customPaginate),
      },
      approveTotalPages: 0,
      currentPage: 1,
      tableHeadersLocal: {
        id: 1,
        practice: "Rushden Medical Center",
        department: "Admin Manager",
        position: "Interviews",
        "contract-type": "Permanent",
      },
      newRecruitment: [
        {
          id: 1,
          practice: "Rushden Medical Center",
          department: "Admin Manager",
          position: "Interviews",
          contract_type: "Permanent",
        },
        {
          id: 2,
          practice: "County Medical Center",
          department: "Receptionist",
          position: "Contracting",
          contract_type: "Full Time",
        },
        {
          id: 3,
          practice: "Brooks Medical Center",
          department: "Telephone Handler",
          position: "Recruiting",
          contract_type: "Casual",
        },
        {
          id: 4,
          practice: "County Medical Center",
          department: "GP",
          position: "Interviews",
          contract_type: "Zero-Hour",
        },
        {
          id: 5,
          practice: "County Medical Center",
          department: "Admin",
          position: "Interviews",
          contract_type: "Full Time",
        },
        {
          id: 6,
          practice: "Rushden Medical Center",
          department: "Admin Manager",
          position: "Interviews",
          contract_type: "Full Time",
        },
        {
          id: 7,
          practice: "Brooks Medical Center",
          department: "Receptionist",
          position: "Contracting",
          contract_type: "Part Time",
        },
        {
          id: 8,
          practice: "Rushden Medical Center",
          department: "GP",
          position: "Interviews",
          contract_type: "Casual",
        },
        {
          id: 9,
          practice: "County Medical Center",
          department: "Admin",
          position: "Interviews",
          contract_type: "Casual",
        },
        {
          id: 10,
          practice: "Brooks Medical Center",
          department: "Admin Manager",
          position: "Interviews",
          contract_type: "Zero-Hour",
        },
      ],
    };
  },
  computed: {
    getNewApplicationsHiringRequest() {
      return this.$store.getters["HQnewHire/getNewApplicationsHiringRequest"];
    },

    getApprovedHiringRequest() {
      return this.$store.getters["HQnewHire/getApprovedHiringRequest"];
    },

    getEscalatedHiringRequest() {
      return this.$store.getters["HQnewHire/getEscalatedHiringRequest"];
    },
    getDeclinedHiringRequest() {
      return this.$store.getters["HQnewHire/getDeclinedHiringRequest"];
    },
    approvedTotalPages() {
      return this.$store.getters["HQnewHire/getApprovedTotalPages"];
    },
    // Keys from object passed will become table headers
    // FOR DYNAMIC HEADER NAMES FROM OBJECT PASSED
    // tableHeaders() {
    //   return Object.keys(this.tableHeadersLocal)
    //     .slice(1)
    //     .map((header, index) => {
    //       return {
    //         index: index,
    //         identifier: header,
    //       };
    //     });
    // },
    //
    // tableRows() {
    //   return this.newRecruitment.map((obj) => {
    //     return Object.keys(obj).map((key, idx) => {
    //       return {
    //         id: Object.values(obj)[0],
    //         key: key,
    //         value: Object.values(obj)[idx + 1],
    //       };
    //     });
    //   });
    // },
  },
  methods: {
    reRoute() {
      this.$router.back();
    },
    async fetchAllHiringRequests(status, page) {
      console.log(page);
      try {
        await this.$store.dispatch("HQnewHire/fetchAllHiringRequests", [
          {
            status: status,
            page,
          },
        ]);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    myCallback(e, contractType) {
      this.fetchAllHiringRequests(contractType, e);
      console.log(e, contractType);
    },
    async fetchPageData(e, page) {
      // Update the current page and fetch data for the selected page
      this.currentPage = page;
      const response = await this.fetchAllHiringRequests(e, this.currentPage);
      console.log(response);
    },
  },
};
</script>
