<template>
  <div
    class="relative overflow-x-auto border-2 border-grey-100 rounded-md bg-white"
  >
    <div class="px-6 py-3">
      <span class="text-lg font-md">{{ title }}</span>
    </div>
    <div class="border-b-2 border-[#ECECEC] drop-shadow-2xl w-full"></div>
    <table class="w-full text-sm text-left">
      <thead class="text-xs text-textGray border-b">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 font-medium tracking-wider"
            v-for="header in props.tableHeadings"
            :key="header"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id" class="border-b">
          <td class="px-6 py-4 w-36">
            <router-link
              :to="{
                name: link,
                params: { [myParameter]: item.id },
              }"
            >
              <p>{{ item.practice.practice_name }}</p>
            </router-link>
          </td>
          <td class="px-6 py-4 w-36">
            <p v-if="item.department">{{ item.department.name }}</p>
          </td>
          <td class="px-6 py-4 w-36">
            <p class="">
              {{ item.job_title.toUpperCase() }}
            </p>
          </td>
          <td class="px-6 py-4 w-36">
            {{ item.contract_type.toUpperCase() }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex items-center justify-center py-6 lg:px-0 sm:px-6 px-6">
      <div class="w-full flex items-center justify-between">
        <div
          class="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer"
        >
          <p
            v-if="currentPage > 1"
            class="text-sm ml-3 font-medium leading-none"
            @click="goToPage(currentPage - 1)"
          >
            Previous
          </p>
        </div>
        <div class="sm:flex hidden">
          <p
            v-for="page in totalPages"
            :key="page"
            class="text-sm font-medium leading-none cursor-pointer text-gray-600 hover:text-indigo-700 border-transparent hover:border-indigo-400 pt-3 mr-4 px-2"
            :class="{
              'text-indigo-700 border-indigo-400': currentPage === page,
            }"
            @click="goToPage(page)"
          >
            {{ page }}
          </p>
        </div>
        <div
          class="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer"
        >
          <p
            v-if="currentPage < totalPages"
            class="text-sm font-medium leading-none mr-3"
            @click="goToPage(currentPage + 1)"
          >
            Next
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
const emit = defineEmits();
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  tableTitle: {
    type: String,
    required: true,
  },
  tableHeadings: {
    type: Array,
    required: true,
  },
  link: {
    type: String,
    required: true,
  },
  myParameter: {
    type: String,
  },
  totalPages: {
    type: Number,
  },
});

const title = ref(props.tableTitle);

const currentPage = ref(1);

function goToPage(page) {
  if (page >= 1 && page <= props.totalPages) {
    currentPage.value = page;

    emit("page-change", currentPage.value);
  }
}
</script>
